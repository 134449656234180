import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/Container';
import DBMobileViews from 'common/src/components/DBMobileViews';
import Fade from 'react-reveal/Fade';

import PartnerSectionWrapper from './partner.style';
import Partner from 'common/src/assets/image/saasModern/mobile-5-p-500.png';

const PartnerSection = ({
  row,
  col,
  title,
  description,
  button,
  textArea,
  imageArea,
}) => {
  return (
    <PartnerSectionWrapper>
      <Container>
        <Fade bottom>
          <Box {...row}>
            <Box {...col} {...textArea}>
              <Heading {...title} content="Para pequeñas y medianas empresas" />
              <Text
                {...description}
                content="¿Te gustaría aumentar tus ventas, tener control sobre tus ingresos y gastos? Por suerte llegó Circulo Cuadro."
              />
            </Box>
          </Box>
        </Fade>
        <DBMobileViews />
      </Container>
    </PartnerSectionWrapper>
  );
};

PartnerSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  textArea: PropTypes.object,
  imageArea: PropTypes.object,
};

PartnerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  imageAreaRow: {
    flexDirection: 'row-reverse',
  },
  col: {
    pr: '15px',
    pl: '15px',
  },
  textArea: {
    width: ['100%', '100%', '100%', '100%', '100%'],
    textAlign: 'center',
    marginBottom: '25px',
  },
  imageArea: {
    width: ['100%', '100%', '45%', '50%', '58%'],
    mb: ['40px', '40px', '0', '0', '0'],
  },
  title: {
    fontSize: ['24px', '32px', '38px', '42px'],
    fontWeight: '700',
    letterSpacing: '-0.025em',
    mb: '10px',
    textAlign: 'center',
  },
  description: {
    fontSize: '16px',
    color: '#5d646d',
    lineHeight: '1.75',
    mb: '33px',
    textAlign: 'center',
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'secondaryWithBg',
    minWidth: '150px',
  },
};

export default PartnerSection;
