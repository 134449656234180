import styled from 'styled-components';

const DBMobileViewsStyle = styled.div`
  .w-tabs {
    position: relative;
  }
  .horizontal-tabs {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;

    .w-tab-menu {
      position: relative;
    }

    .vertical-tabs-menu {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    .w-tab-content {
      position: relative;
      display: block;
      overflow: hidden;
    }

    .horizontal-tabs-content {
      padding-left: 48px;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }

    .w-inline-block {
      max-width: 100%;
      display: inline-block;
    }

    .w-tab-link {
      position: relative;
      display: inline-block;
      vertical-align: top;
      text-decoration: none;
      padding: 9px 30px;
      text-align: left;
      cursor: pointer;
      color: #222222;
      background-color: #dddddd;
    }

    .tab-button {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      padding: 12px 20px;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      border-radius: 10px;
      background-color: transparent;
      opacity: 0.5;
      -webkit-transition: background-color 200ms ease, opacity 200ms ease;
      transition: background-color 200ms ease, opacity 200ms ease;
    }

    .tab-button:hover {
      opacity: 1;
    }

    .w-tab-link.w--current {
      background-color: #c8c8c8;
    }

    .tab-button.w--current {
      background-color: #e9eef1;
      opacity: 1;
      color: #353e44;
    }

    .tab-icon {
      margin-right: 16px;
    }

    .text-block-23 {
      color: #353e44;
    }

    .w-tab-pane {
      position: relative;
      /*display: none;*/
      opacity: 0;
      height: 0;
      overflow: hidden;
      transition: opacity 1s cubic-bezier(0.4, 0, 0.2, 1);
    }

    .w--tab-active {
      /*display: block;*/
      opacity: 1;
      height: auto;
    }

    .content-pair {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      overflow: visible;
      width: 100%;
      max-width: 100%;
      -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
      justify-content: space-around;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }

    .mobile-screenshot-wrapper {
      position: relative;
      max-width: 20vw;
    }

    img {
      max-width: 100%;
      vertical-align: middle;
      display: inline-block;
    }

    .mobile-screenshot-text {
      max-width: 360px;
      margin-top: 28px;
    }

    .screenshot-arrow {
      position: relative;
      left: -48px;
      margin-bottom: 24px;
    }
  }

  @media screen and (max-width: 1100px) {
    .horizontal-tabs .screenshot-arrow {
      left: 0;
    }
  }

  @media screen and (max-width: 991px) {
    .horizontal-tabs {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    .horizontal-tabs .vertical-tabs-menu {
      margin-bottom: 24px;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
      justify-content: space-around;
    }
  }

  @media screen and (max-width: 600px) {
    .horizontal-tabs .vertical-tabs-menu {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-flex: 0;
      -webkit-flex: 0 auto;
      -ms-flex: 0 auto;
      flex: 0 auto;
    }

    .horizontal-tabs .vertical-tabs-menu .tab-button.w--current {
      width: 100%;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      text-align: left;
    }

    .horizontal-tabs .horizontal-tabs-content {
      padding-left: 0;
    }

    .horizontal-tabs .vertical-tabs-menu .tab-icon {
      max-height: 24px;
    }

    .horizontal-tabs .mobile-screenshot-wrapper {
      max-width: 60vw;
    }

    .horizontal-tabs .mobile-screenshot-text {
      margin-top: 24px;
      padding-left: 0px;
      text-align: center;
    }

    .horizontal-tabs .content-pair {
      flex-direction: column;
    }

    .horizontal-tabs .screenshot-arrow {
      display: none;
    }
  }
`;

DBMobileViewsStyle.displayName = 'DBMobileViewsStyle';

export default DBMobileViewsStyle;
