import React from 'react';
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import Label from 'common/src/components/Label';
import { createTheme } from 'common/src/theme/circuloCuadro';
import { ThemeProvider } from '@material-ui/core/styles';
import productImg from 'common/src/assets/image/saasModern/product_freelancer.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    backgroundColor: theme.palette.background.dark,
    //display: 'flex',
    height: '100%',
    minHeight: '100%',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      minHeight: 'auto',
    },
    //flexDirection: 'column',
    paddingBottom: 50,
    paddingTop: 50,
  },
  product: {
    position: 'relative',
    padding: theme.spacing(5, 3),
    cursor: 'pointer',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  productImage: {
    borderRadius: theme.shape.borderRadius,
    position: 'absolute',
    top: -24,
    left: theme.spacing(3),
    height: 48,
    width: 48,
    fontSize: 24,
  },
  recommendedProduct: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  // chooseButton: {
  //   backgroundColor: theme.palette.common.white
  // }
}));

const Pricing = ({ priceBtnLink }) => {
  const classes = useStyles();

  const settings = {
    direction: 'ltr',
    responsiveFontSizes: true,
    theme: 'CIRCULO_CUADRO',
  };

  const priceBtnHandler = () => {
    window.location.href = priceBtnLink;
  };

  return (
    <ThemeProvider theme={createTheme(settings)}>
      <Box>
        <Box style={{ textAlign: 'center' }}>
          <Typography variant="h2" color="textPrimary">
            Comienza Ahora
          </Typography>
          <Typography
            variant="h4"
            color="textSecondary"
            style={{ marginTop: '10px' }}
          >
            Regístrate completamente gratis, sin compromisos.
          </Typography>
        </Box>
        <Box mt="60px">
          <Container maxWidth="lg">
            <Grid container spacing={4} justify="center">
              <Grid item md={6} xs={12}>
                <Paper
                  className={classes.product}
                  elevation={1}
                  onClick={priceBtnHandler}
                >
                  <img
                    alt="Product"
                    className={classes.productImage}
                    src={productImg}
                  />
                  {/* <Typography
                    component="h3"
                    gutterBottom
                    variant="overline"
                    color="textSecondary"
                  >
                    Mensual
                  </Typography> */}
                  <div>
                    <Typography
                      component="span"
                      display="inline"
                      variant="h3"
                      color="textPrimary"
                    >
                      Suscripción Gratuita
                    </Typography>
                    {/*                     <Typography
                      component="span"
                      display="inline"
                      variant="subtitle2"
                      color="textSecondary"
                    >
                      /mes
                    </Typography> */}
                  </div>
                  <Box my={2}>
                    <Divider />
                  </Box>
                  <Typography variant="body2" color="textPrimary">
                    Carga clientes ilimitados
                    <br />
                    Vende productos y servicios
                    <br />
                    Puede crear cotizaciones y órdenes de compra
                    <br />
                    Automatiza tus cobros a tus clientes
                    <br />
                    Administra tus proyectos con tus clientes
                    <br />
                    Crece tu negocio con el apoyo de estadísticas
                    <br />
                    Comisión de 1% + 1.00 mxn por transacción (adicional a las
                    tasas de stripe)
                    <br />
                  </Typography>
                  <Box my={2}>
                    <Divider />
                  </Box>
                  <Button
                    variant="contained"
                    fullWidth
                    color="secondary"
                    className={classes.chooseButton}
                    onClick={priceBtnHandler}
                  >
                    Regístrate
                  </Button>
                  <Typography
                    style={{
                      marginTop: '10px',
                      textAlign: 'center',
                      lineHeight: 'normal',
                    }}
                    component="p"
                    gutterBottom
                    variant="caption"
                    color="textSecondary"
                  >
                    Crea tu cuenta sin una tarjeta.
                    {/* <br />
                    Cancela en cualquier momento. */}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Pricing;
