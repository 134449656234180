import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Input from 'common/src/components/Input';
import Container from 'common/src/components/UI/Container';

import NewsletterWrapper, { ContactFormWrapper } from './newsletter.style';

const Newsletter = ({
  sectionWrapper,
  textArea,
  buttonArea,
  buttonStyle,
  title,
  description,
}) => {
  return (
    <Box {...sectionWrapper} as="section">
      <Container>
        <NewsletterWrapper>
          <Box {...textArea}>
            <Heading
              content="Eleva tu negocio a su máximo potencial"
              {...title}
            />
            <Text
              content="Recibe una consultoría totalmente gratis. Te apoyaremos en el proceso de gestión e implementación de tu dashboard inteligente para que comiences a operar de inmediato."
              {...description}
            />
          </Box>
          <Box {...buttonArea}>
            <form
              method="post"
              action="https://godspeedmedia.us3.list-manage.com/subscribe/post?u=51ad6a7a8a940ed1bea548df4&amp;id=10d410be52"
            >
              <ContactFormWrapper>
                <Input
                  inputType="email"
                  label="Correo"
                  iconPosition="right"
                  isMaterial={true}
                  className="email_input"
                  arial-label="email"
                  required
                  name="EMAIL"
                />
                <Button {...buttonStyle} type="submit" title="Agendar Sesión" />
              </ContactFormWrapper>
            </form>
          </Box>
        </NewsletterWrapper>
      </Container>
    </Box>
  );
};

Newsletter.propTypes = {
  sectionWrapper: PropTypes.object,
  textArea: PropTypes.object,
  buttonArea: PropTypes.object,
  buttonStyle: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
};

Newsletter.defaultProps = {
  sectionWrapper: {
    pt: ['20px', '40px', '60px', '80px'],
    pb: ['20px', '40px', '60px', '80px'],
  },
  textArea: {
    mb: ['40px', '40px', '40px', '0', '0'],
    pr: ['0', '0', '0', '80px', '100px'],
  },
  title: {
    fontSize: ['18px', '20px', '22px', '24px', '26px'],
    fontWeight: '500',
    color: '#fff',
    lineHeight: '1.34',
    mb: ['14px', '14px', '14px', '14px', '13px'],
    textAlign: ['center', 'center', 'center', 'left', 'left'],
    letterSpacing: '-0.025em',
  },
  description: {
    fontSize: ['14px', '14px'],
    fontWeight: '400',
    color: '#fefefe',
    lineHeight: '1.7',
    mb: 0,
    textAlign: ['center', 'center', 'center', 'left', 'left'],
  },
  buttonArea: {
    zIndex: 1,
  },
  buttonStyle: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '700',
    pl: '30px',
    pr: '30px',
    colors: 'secondaryWithBg',
    //color: '#03103b'
  },
};

export default Newsletter;
